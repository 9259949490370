<template>
  <div class="Message" :class="!full?'not-full':''">


    <LoadingProgress :type="loadingType" :loading="valueLoading"></LoadingProgress>




    <div :class="fixedCenter?'fixed-center':''">



    <v-alert
      v-if="showErr"
      prominent
        outlined
      type="error"
        border="left"
        dense
        style="background: #fff3f3!important;text-align:center;margin-bottom:16px"
    >
        {{ valueErr }}
         
          <v-btn class="ml-4" v-if="errRefresh" color="primary" @click="$emit('refresh')"  >
            <v-icon 
            left 
            class="pl-2"
            >mdi-refresh-circle</v-icon>
            </h2>
            {{ $t('refresh') }}
          </v-btn>

    </v-alert>

<!-- 
      <v-alert
        v-if="showErr"
        dense
        outlined
        type="error"
      >
        {{ valueErr }}


        <span v-if="errRefresh" style="float:right">
          <v-btn color="primary" @click="$emit('refresh')" small >
            <v-icon 
            left 
            >mdi-refresh-circle</v-icon>
            </h2>
            {{ $t('refresh') }}
          </v-btn>
        </span>

      </v-alert>
       -->

      <v-alert
        v-if="showWarning"
        outlined
        type="warning"
        prominent
        border="left"
        dense
        style="background: #fffaf4!important;"
      >

        {{ valueWarning }}

        <span v-if="warningRefresh" style="float:right">
          <v-btn color="primary" @click="$emit('refresh')" small >
            <v-icon 
            left 
            >mdi-refresh-circle</v-icon>
            </h2>
            {{ $t('refresh') }}
          </v-btn>
        </span>

      </v-alert>
<!-- 
      <v-alert
        v-if="showWarning"
        dense
        outlined
        type="warning"
      >
        {{ valueWarning }}

        <span v-if="warningRefresh" style="float:right">
          <v-btn color="primary" @click="$emit('refresh')" small >
            <v-icon 
            left 
            >mdi-refresh-circle</v-icon>
            </h2>
            {{ $t('refresh') }}
          </v-btn>
        </span>

      </v-alert> -->
      
      <v-alert
        v-if="showNotice"
        type="info"
        outlined
        class="text-center"
        border="left"
        dense
        style="background: rgb(238 248 255)!important;"
      >
        {{ valueNotice }}
      </v-alert>

      <v-alert
        v-if="showInfo"
        dense
        outlined
        type="info"
      >
        <!-- <pre style="font-size:12px;font-family:arial">{{info}}</pre> -->
            <div v-for="item in valueInfo">{{ item }}</div>
      </v-alert>
      
      <v-alert
        v-if="showSuccess"
        dense
        outlined
        type="success"
      >
        {{ valueSuccess }}
      </v-alert>
      
      <v-alert
        v-if="debug"
        dense
        type="info"
      >
        <pre style="font-size:12px;font-family:arial">{{debug}}</pre>
      </v-alert>
    
      <v-alert
        v-if="loadingMsg"
        dense
        type="info"
      >
        <v-progress-circular indeterminate size="24" style="margin-top:10px"></v-progress-circular>
        {{loadingMsg}}
        
      </v-alert>
    
      <v-overlay
      
        :value="showOverlay"
        :absolute="true"
        style="z-index:9999;max-width:100%;"

      >
        <div style="width:300px;max-width:100%;">
          <v-alert v-if="valueMsg && valueMsg.length" color="cyan darken-1">
            <div v-for="item in valueMsg" v-html="item"></div>
            <v-progress-circular indeterminate size="24" style="margin-top:10px"></v-progress-circular>


            <span v-if="hasCancel" style="float:right">
              <v-btn color="primary" @click="$emit('cancel')" small >
                <v-icon 
                left 
                >mdi-cancel</v-icon>
                </h2>
                {{ $t('cancel') }}
              </v-btn>
            </span>


          </v-alert>
        </div>
      </v-overlay>          
            
      
      
    </div>
  </div>
</template>

<style lang="less">
  .Message.not-full .v-overlay {
    align-items: normal;
    padding-top: 100px
  }
  .fixed-center {
    .v-overlay__content {
      position: fixed;
      left: 50%;
      top: 50%;
      /* transition: tra; */
      transform: translate(-50%, -50%);
    }
  }
</style>

<script>

import { isNotEmpty, isArray } from '../libs/typeMatch.fun.js';
import LoadingProgress from './LoadingProgress';

const Message = {
  props: {
    loadingType: {
      default: 1
    },
    loading: {
      default: false
    },
    notice: {
      defatul: ''
    },
    err: null,
    warning: null,
    info: null,
    success: null,
    msg: null,
    debug: null,
    loadingMsg: null,
    full: false,
    errRefresh: null,
    hasCancel: {
      default: false
    },
    mesg: {
      default: null
    },
    warningRefresh: null,
    fixedCenter: {
      default: false
    }
  },
  
  components: {
    LoadingProgress
  },

  computed:{
    showErr(){
      if (isNotEmpty(this.err)){
        return true;
      }
      if (isNotEmpty(this.mesg) && isNotEmpty(this.mesg.err)){
        return true;
      }
      return false;
    },
    showWarning(){
      if (isNotEmpty(this.warning)){
        return true;
      }
      if (isNotEmpty(this.mesg) && isNotEmpty(this.mesg.warning)){
        return true;
      }
      return false;
    },
    showNotice(){
      if (isNotEmpty(this.notice)){
        return true;
      }
      if (isNotEmpty(this.mesg) && isNotEmpty(this.mesg.notice)){
        return true;
      }
      return false;
    },
    showInfo(){
      if (isNotEmpty(this.info)){
        return true;
      }
      if (isNotEmpty(this.mesg) && isNotEmpty(this.mesg.info)){
        return true;
      }
      return false;
    },
    showSuccess(){
      if (isNotEmpty(this.success)){
        return true;
      }
      if (isNotEmpty(this.mesg) && isNotEmpty(this.mesg.success)){
        return true;
      }
      return false;
    },
    showOverlay(){
      if (this.msg && this.msg.length){
        return true;
      }
      if (this.mesg && this.mesg.msg && this.mesg.msg.length){
        return true;
      }
      return false;
    },
    valueLoading(){
      if (this.loading){
        return true;
      }
      if (this.mesg && this.mesg.loading){
        return true;
      }
      return false;
    },
    valueMsg(){
      if (this.msg && this.msg.length){
        return this.msg;
      }
      if (this.mesg && this.mesg.msg && this.mesg.msg.length){
        return this.mesg.msg;
      }
      return null;
    },
    valueErr(){
      return this.filterValue('err');
    },
    valueWarning(){
      return this.filterValue('warning');
    },
    valueNotice(){
      return this.filterValue('notice');
    },
    valueInfo(){
      let v = this.filterValue('info');
      if (!isArray(v)){
        return [v];
      }else{
        return v;
      }
    },
    valueSuccess(){
      return this.filterValue('success');
    }
  },
  methods:{
    filterValue(field){
      if (this[field] && isArray(this[field]) && (1 == this[field].length)){
        return this[field][0];
      }

      if (this.mesg && this.mesg[field]){
        if (isArray(this.mesg[field]) && (1 == this.mesg[field].length)){
          return this.mesg[field][0];
        }else{
          return this.mesg[field];
        }
      }

      return this[field];
    }
  }
}

export { Message } ;
export default Message ;

</script>