<template>
  <div>

    <div class="div-date"  @click="date_OnClick()">
      {{ showValue }}
    </div>

    <div style="opacity: 0;height:1px; overflow:hidden">
      <el-date-picker
        ref="picker"
        v-model="date"
        type="datetime"
        :placeholder="label"
        style="width:100%;"
        @change="date_OnChange($event)"
        :class="dense?'el-date-picker-dense':''"
      >
      </el-date-picker>
    </div>


    <!-- <TextDate
      v-model="date"
    ></TextDate> -->
  </div>
</template>

<style lang="less">
  .el-date-picker-dense {
    .el-input__inner {
      height: 25px;
    }
  }
  .div-date {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    height: 31px;
    cursor: pointer;
    text-align: left;

    &:hover {
      border-color: #000;
    }
  }
</style>

<script>

  // import TextDate from './TextDate.vue';

  export default {
    props:{
      value:{
        default: () => new Date().getTime()
      },
      label: {
        default: 'Select time'
      },
      dense: {
        default: false
      },
      utc: {
        default: false
      }
    },
    components: {
      // TextDate
    },
    data () {
      return {
        date: this.$formatDate(new Date().getTime(), false, true)
      }
    },
    // async mounted(){
    //   this.date = this.value;
    // },
    watch:{
      value:{
   　　 handler(newVal,oldVal){
          // console.log('watch value', newVal, this.utc);
          let v = newVal;
          // if (this.utc){
          //   let ot = new Date().getTimezoneOffset();
          //   v += ot * 60 * 1000;
          //   v += ot * 60 * 1000;
          //   // v -= ot * 60 * 1000;
          // }
          this.date = this.$formatDate(v, false, true)
        },
        immediate: true
      }
    },
    computed:{
      showValue(){
        if (this.value){
          return this.$formatDate(this.value, false, true);
        }else{
          return 'Select a date'
        }
      }
    },
    methods:{
      formatDate(value, hide_second){

          if (!value){
            return null
          }

          if ('object' == typeof value){
            try {
              return this.getDateTimeString(value, hide_second)
            }catch(e){
              //nothing
            }
          }

          let n = parseInt(value)
          if (n < 9999999999){
            n *= 1000;
          }

          let date = new Date(n);
          return this.getDateTimeString(date, hide_second)
      },
      getDateTimeString(date, hide_second){
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        if (hide_second){
          return y + '-' + MM + '-' + d;
        }else{
          return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
        }
      },
      date_OnClick(){
        this.$refs.picker.focus();
      },
      date_OnChange($event){
        let v = new Date($event).getTime();

        if (this.utc){
          let ot = new Date().getTimezoneOffset();
          v -= ot * 60 * 1000;
        }

        v = Math.ceil(v / 1000);
        this.$emit('input', v);
      }
    }
  }
</script>
