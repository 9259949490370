<template>
  <div>
    <div v-if="readonly">
        <v-text-field
          v-model="date"
          readonly
          prepend-icon="mdi-calendar"
          :prefix="prefix"
          hide-details
          dense
        ></v-text-field>
    </div>
    <div v-else>
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            readonly
            prepend-icon="mdi-calendar"
            :prefix="prefix"
            v-bind="attrs"
            v-on="on"
            hide-details
            dense
            class="v-text-field-keep-line"
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" no-title @input="datePicker_OnInput()"
        :allowed-dates="allowedDates"
        ></v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<style lang="less">
</style>

<script>
  export default {
    props:{
      readonly: {
        default: false
      },
      value:{
        default: ''
      },
      prefix: {
        default: ''
      }
    },
    components: {
    },
    data () {
      return {
        date: '', //new Date().toISOString().substr(0, 10),
        dateFormatted: '', //new Date().toISOString().substr(0, 10),
        menu1: false,
        menu2: false,
        formData:{
          customer: '',
          incoterm: 'incoterm'
        },
        mesg:{
          err: '',
          warning: '',
          info: '',
          success: '',
          msg: [],
          loading: false
        },
        loading: false,
        desserts: null
      }
    },
    async mounted(){
      this.date = this.value;
      // this.$emit('input', this.date);
    },
    watch:{
      value:{
   　　 handler(newVal,oldVal){
          this.date = this.value;
          // this.$emit('input', this.date);
        }
      }
    },
    computed:{
    },
    methods:{
      allowedDates(val){
        // if (RUNTIME_CONFIG.DEV.QUOTE_EXPIRY_DATE_ALLOWED){
        //   return true;
        // }

        if (this.$IS_DEV){
          return true;
        }

        let currentDatetime = new Date(val).getTime();
        let nowTime = new Date().getTime();
        return (currentDatetime > nowTime)
      },
      datePicker_OnInput(){
        this.menu1 = false;
        console.log('datePicker_OnInput', this.date);
        this.$emit('input', this.date);
        this.$emit('change', this.date);
      }
    }
  }
</script>
