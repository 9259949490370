<template>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn 
        :loading="loading" 
        :disabled="disabled"
        style="min-width:0;padding:0"
        v-on="on"
        text small>
          <v-icon dark v-if="4 == iconType" color="blue-grey lighten-3">{{ iconName }}</v-icon>
          <v-icon dark v-else color="error">{{ iconName }}</v-icon>
          <span v-if="3 == iconType">Remove</span>
          <span v-if="text">{{ text }}</span>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="btnRemove_OnClick()">
          <v-list-item-title>Remove</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<style lang="less" scoped>
</style>

<script>
	export default {
	  props: {
      text: {
        default: ''
      },
      disabled: {
        default: false
      },
	  	loading: {
	  		default: false
	  	},
      iconType: {
        default: 1
      }
	  },
	  computed:{
      iconName(){
        switch(this.iconType){
          case 1:
            return 'mdi-minus-circle'
            break;
          case 2:
            return 'mdi-trash-can'
            break;
          case 3:
            return 'mdi-trash-can'
            break;
          case 4:
            return 'mdi-trash-can'
            break;
        }
        return 'mdi-minus-circle'
      }
	  },
	  methods: {
	  	btnRemove_OnClick(){
	  		this.$emit('click');
	  	}
	  }
	}

</script>
