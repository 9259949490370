<template>
  <span>
    {{ showtime }}
  </span>
</template>


<script>


import { distanceOfTimeInWords, formatDateTs, YEAR, WEEK } from '../../timestamp.js';
import { currentUnixTimestamp } from '@/libs/const';

const UnixTimestampShow = {
  components: {
  },
  props: {
    v: {
      default: 0
    },
  },
  computed:{
    showtime(){

      if (this.v){
        let seconds = parseInt(this.v)
        // if (seconds > 999999999999999999){
        //   seconds = Number(seconds / 1000000000)
        // }else 
        if (seconds > 9999999999){
          seconds = Number(seconds / 1000000000)
        }
        let dur = currentUnixTimestamp() - seconds;
        // if (dur > 0){
        //   return this.$formatDate(new Date(seconds * 1000));
        // }
        const isRelative = (dur < YEAR);
        const text = isRelative
          ? distanceOfTimeInWords(dur)
          : this.$formatDate(new Date(seconds * 1000));
        return text;
      }
      return ''

    }
  }
}

export { UnixTimestampShow } ;
export default UnixTimestampShow ;

</script>