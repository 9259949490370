import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // {
  //   path: '/app_redirect',
  //   name: 'app_redirect',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AppRedirect.vue')
  // },
  // {
  //   path: '/app',
  //   name: 'app_home',
  //   component: HomeView
  // },
  // {
  //   path: '/app.html',
  //   name: 'app',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/AppRedirect.vue')
    // component: HomeView
  },
  // {
  //   path: '/homeview',
  //   name: 'homeview',
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/AppRedirect.vue')
  //   component: HomeView
  // },
  // {
  //   path: '/home_page',
  //   name: 'home_page',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/HomePage/index.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  // {
  //   path: '/my-wallet',
  //   name: 'my-wallet',
  //   component: function () {
  //     return import('../views/MyWallet/index.vue')
  //   },
  //   // redirect:'/user/home_page',
  //   redirect:'/my-wallet/home',
  //   children: [
  //     {
  //       path: 'home',
  //       name: 'my-wallet-home',
  //       component: function () {
  //         return import('../views/MyWallet/Home.vue')
  //       },
  //     },
  //     {
  //       path: 'generate-wallet',
  //       name: 'generate-wallet',
  //       component: function () {
  //         return import('../views/MyWallet/GenerateWallet.vue')
  //       },
  //     },
  //     {
  //       path: 'import-wallet',
  //       name: 'import-wallet',
  //       component: function () {
  //         return import('../views/MyWallet/ImportWallet.vue')
  //       },
  //     },
  //     {
  //       path: 'wallet-details',
  //       name: 'wallet-details',
  //       component: function () {
  //         return import('../views/MyWallet/WalletDetails.vue')
  //       },
  //     },
  //   ]
  // },
  // {
  //   path: '/wallet-ii',
  //   name: 'wallet-ii',
  //   component: function () {
  //     return import('../views/WalletII/index.vue')
  //   },
  //   // redirect:'/user/home_page',
  //   redirect:'/wallet-ii/home',
  //   children: [
  //     {
  //       path: 'home',
  //       name: 'wallet-ii-home',
  //       component: function () {
  //         return import('../views/WalletII/Home.vue')
  //       },
  //     },
  //     {
  //       path: 'my-account',
  //       name: 'wallet-ii-my-account',
  //       component: function () {
  //         return import('../views/WalletII/MyAccount.vue')
  //       },
  //     },
  //   ]
  // },
  {
    path: '/wallet',
    name: 'wallet',
    component: function () {
      return import('../views/Wallet/index.vue')
    },
    // redirect:'/user/home_page',
    redirect:'/wallet/my-tokens',
    children: [
      {
        path: 'login',
        name: 'wallet-login',
        component: function () {
          return import('../views/Wallet/Login.vue')
        },
      },
      {
        path: 'swap',
        name: 'wallet-swap',
        component: function () {
          return import('../views/Wallet/Swap.vue')
        },
      },
      {
        path: 'my-tokens',
        name: 'wallet-my-tokens',
        component: function () {
          return import('../views/Wallet/MyTokens.vue')
        },
      },
      {
        path: 'stake',
        name: 'wallet-stake',
        component: function () {
          return import('../views/Wallet/Stake.vue')
        },
      },
      {
        path: 'wrap',
        name: 'wallet-wrap',
        component: function () {
          return import('../views/Wallet/Wrap.vue')
        },
      },
      {
        path: 'rewards',
        name: 'wallet-rewards',
        component: function () {
          return import('../views/Wallet/Rewards.vue')
        },
      },
      {
        path: 'pools',
        name: 'wallet-pools',
        component: function () {
          return import('../views/Wallet/Pools.vue')
        },
      },
    ]
  },
  // {
  //   path: '/user',
  //   name: 'user',
  //   component: function () {
  //     return import('../views/User/index.vue')
  //   },
  //   // redirect:'/user/home_page',
  //   redirect:'/user/home_organisation',
  //   children: [
  //     {
  //       path: 'home_page',
  //       name: 'user_home_page',
  //       component: function () {
  //         return import('../views/HomePage/index.vue')
  //       },
  //     },
  //     {
  //       path: 'home_organisation',
  //       name: 'user_home_organisation',
  //       component: function () {
  //         return import('../views/Organisation/index.vue')
  //       },
  //     },
  //     {
  //       path: 'home_monitor',
  //       name: 'user_home_monitor',
  //       component: function () {
  //         return import('../views/Monitor/index.vue')
  //       },
  //     },
  //   ]
  // },
  // {
  //   path: '/test_io_pem',
  //   name: 'test_io_pem',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/test/IOPem.vue')
  // },
  // {
  //   path: '/test_web_authn',
  //   name: 'test_web_authn',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/test/WebAuthn.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//添加路由守卫
router.beforeEach((to, from, next) => {
  // console.log('in beforeEach')

  if (to.name != 'home'){
    next({name:'home'})
    return
  }


  // if (-1 != to.fullPath.indexOf('/wallet/')){
  //   if (store?.getters.C2IC_IsConnected){
  //     next()
  //     return;
  //   }else{
  //     if (to.name == 'wallet-login'){
  //       next()
  //       return;
  //     }else{
  //       next({name:'wallet-login'})
  //       return;
  //     }
  //   }
  // }
  next()
})

export default router
