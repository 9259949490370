<template>

  <div style="text-align:right;">
    <v-btn v-if="1 == btnType" :loading="loading" :color="color" @click="$emit('click')" small dark >
      <v-icon 
      :left="!min"
      >mdi-refresh-circle</v-icon>
      <span v-if="!min">Refresh</span>
    </v-btn>
    <v-btn v-if="2 == btnType" :loading="loading" :color="color" @click="$emit('click')" outlined fab x-small dark >
      <v-icon 
      >mdi-refresh</v-icon>
      <span v-if="!min"></span>
    </v-btn>

    <!-- <v-btn-toggle class="my-btn-group"> -->
      <v-btn 
        v-if="3 == btnType" 
        @click="$emit('click')" 
        :loading="loading"
        class="btnRefresh3"
        outlined
        >

        <v-icon >mdi-refresh</v-icon>
      </v-btn>
    <!-- </v-btn-toggle> -->
  </div>
      
</template>

<style lang="less">
  .btnRefresh3 {
    height:40px!important;
    margin-top:-12px;
    padding:0 0px!important;
    min-width:40px!important;
    border-color:rgba(0,0,0,0.15);
    color:rgba(0,0,0,0.8)!important;
  }

  .theme--dark {
    .btnRefresh3 {
      border-color:rgba(255,255,255,0.15);
      color:rgba(255,255,255,0.8)!important;
    }
  }
</style>

<script>


export default {
  props: {
    min: {
      default: false
    },
    loading: {
      default: false
    },
    color: {
      default: 'primary'
    },
    btnType: {
      default: 1
    }
  },
  computed:{
  }
}
</script>