<template>
	<div>
	    <div v-if="1 == type" style="height:2px">
	        <v-progress-linear
	          :active="loading"
	          :indeterminate="loading"
	          :color="'primary'"
	          height="2"
	        ></v-progress-linear>
	    </div>

	    <div v-if="(2 == type) && loading" style="text-align:center;padding-top:50px">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;  display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
				<circle cx="84" cy="50" r="10" fill="#80deea">
				    <animate attributeName="r" repeatCount="indefinite" dur="0.43859649122807015s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
				    <animate attributeName="fill" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#80deea;#00838f;#00acc1;#4dd0e1;#80deea" begin="0s"></animate>
				</circle><circle cx="16" cy="50" r="10" fill="#80deea">
				  <animate attributeName="r" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
				  <animate attributeName="cx" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
				</circle><circle cx="50" cy="50" r="10" fill="#4dd0e1">
				  <animate attributeName="r" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.43859649122807015s"></animate>
				  <animate attributeName="cx" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.43859649122807015s"></animate>
				</circle><circle cx="84" cy="50" r="10" fill="#00acc1">
				  <animate attributeName="r" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.8771929824561403s"></animate>
				  <animate attributeName="cx" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.8771929824561403s"></animate>
				</circle><circle cx="16" cy="50" r="10" fill="#00838f">
				  <animate attributeName="r" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3157894736842104s"></animate>
				  <animate attributeName="cx" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3157894736842104s"></animate>
				</circle>
				<!-- [ldio] generated by https://loading.io/ --></svg>
	    </div>


	    <div v-if="(3 == type) && loading">

				<div class="full-loading">

					<div style="width:200px;height:200px;margin:0 auto;background: none;">

						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:none;display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
						<path fill="none" stroke="#ffffff" stroke-width="8" stroke-dasharray="197.57347473144532 59.015453491210934" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" stroke-linecap="round" style="transform:scale(0.8);transform-origin:50px 50px">
						  <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
						</path>
						</svg>						

					</div>

				</div>

	    </div>	    


	    <div v-if="(4 == type) && loading">

				<div class="full-loading">

					<div style="width:200px;height:200px;margin:0 auto;background: none;">

				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;  display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
				<circle cx="84" cy="50" r="10" fill="#80deea">
				    <animate attributeName="r" repeatCount="indefinite" dur="0.43859649122807015s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
				    <animate attributeName="fill" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#80deea;#00838f;#00acc1;#4dd0e1;#80deea" begin="0s"></animate>
				</circle><circle cx="16" cy="50" r="10" fill="#80deea">
				  <animate attributeName="r" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
				  <animate attributeName="cx" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
				</circle><circle cx="50" cy="50" r="10" fill="#4dd0e1">
				  <animate attributeName="r" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.43859649122807015s"></animate>
				  <animate attributeName="cx" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.43859649122807015s"></animate>
				</circle><circle cx="84" cy="50" r="10" fill="#00acc1">
				  <animate attributeName="r" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.8771929824561403s"></animate>
				  <animate attributeName="cx" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.8771929824561403s"></animate>
				</circle><circle cx="16" cy="50" r="10" fill="#00838f">
				  <animate attributeName="r" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3157894736842104s"></animate>
				  <animate attributeName="cx" repeatCount="indefinite" dur="1.7543859649122806s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.3157894736842104s"></animate>
				</circle>
				<!-- [ldio] generated by https://loading.io/ --></svg>
					</div>

				</div>

	    </div>	    

	</div>

</template>


<style lang="less" scoped>

	.full-loading {
		background-color: rgba(0,0,0,0.5);
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		display: flex;
    align-items: center;
    z-index: 999;


	}

</style>

<script>

const LoadingProgress = {
	props:{
		loading: {
			default: false
		},
		type: {
			default: 1
		}
	},
	components: {
	},
    data () {
      return {
      }
    },
    mounted(){
    },
	methods:{
	}
}

export { LoadingProgress } ;
export default LoadingProgress ;

</script>
