export default ({ IDL }) => {
    return IDL.Service({
        'get_subaccount' : IDL.Func([],[IDL.Vec(IDL.Nat8)],['query']),
        'get_subaccount_string' : IDL.Func([],[IDL.Text],['query']),
        'get_subaccount_hex' : IDL.Func([],[IDL.Text],['query']),
        'select_canister_account_id': IDL.Func([],[IDL.Text],['query']),
    //   'notify_dfx' : IDL.Func([NotifyCanisterArgs], [], []),
    //   'send_dfx' : IDL.Func([SendArgs], [BlockHeight], []),
    });
  };

  
//   "get_subaccount": () -> (vec nat8) query;
//   "get_subaccount_hex": () -> (text) query;
//   "get_subaccount_string": () -> (text) query;