
const RR_CRYPTO_KEY = '&mgJkU7ZTA!TBtaAeC';
function rrck(){
  let d = new Date().getTime();
  d = d / 100000;
  d = Math.round(d);
  return d;
}
export const rrEncrypto = (message) => {
  const CryptoJS = require("crypto-js");
  // let rck = rrck() + (-1 + Math.floor(Math.random() * 3));
  // let rck = rrck() + (-1 + Math.floor(Math.random() * 3));
  let rck = Number(rrck()) + (-1);
  // let rck = rrck() + (-1 + Math.floor(Math.random() * 3));
  let ciphertext = CryptoJS.AES.encrypt(message, `${RR_CRYPTO_KEY}_${rck}`).toString();
  return ciphertext;
}
export const rrDecrypto = (ciphertext) => {
  const CryptoJS = require("crypto-js");
  let rck = rrck();
  let bytes;
  let originalText;
  for (let i = -2; i <= 30; i++){
    try {
      bytes  = CryptoJS.AES.decrypt(ciphertext, `${RR_CRYPTO_KEY}_${(Number(rck)+i)}`);
      originalText = bytes.toString(CryptoJS.enc.Utf8);
      if (originalText){
        return originalText; 
      }
    }catch(e){
      //nothing
    }
  }
  return '{"url":"hello"}'
}

export const formatPaymentMethod = (v) => {

  switch(parseInt(v)){
    case 0:
      return 'ETH';
    case 1:
      return 'ERC20';
    default:
      return 'ETH & ERC20'
  }

}

// strtimes("0.1", 18) return "100000000000000000"; 
const strtimes = (snum, zerocount) => {
// function strtime(snum, zerocount){


  if (!zerocount){
    return snum
  }
  if (!snum){
    return null
  }

  snum = snum.toString();
  let a = snum;

  let dn = snum.indexOf('.');
  // console.log('dn', dn);
  if (-1 == dn){
    //no dot

    a = a.replace('.','');
    for (let i = 0; i < zerocount; i++){
      a = `${a}0`
    }

  }else{

    //has dot
    let length = a.length;
    let dotlen = length - dn - 1; //after dot length
  // console.log('dotlen', dotlen);
  // console.log('zerocount', zerocount);
    a = a.replace('.','');
    if (dotlen <= zerocount){
      //final no dot
      for (let i = 0; i < (zerocount-dotlen); i++){
        a = `${a}0`
      }
    }else{
      //final has dot
      let pos = dotlen-zerocount; //after dot length
  // console.log('a', a);
  // console.log('pos', pos);
      a = a.substring(0, a.length-pos) + '.' + a.substring(a.length-pos);
    }

  }

  let b = '';
  for (let i = 0; i < a.length; i++){
    let c = a[i];
    if ('0' != c){
      b = a.substring(i);
      return b;
    }
    if (('0' == c) && ( (i < a.length-1) && ('.' == a[i+1]) )){
      b = a.substring(i);
      return b;
    }
  }

  return '0';

}
export { strtimes };

// strdiv("10000", 3) return "10";  strdiv("12345", 3) return "12.345"， 
const strdiv = (snum, zerocount) => {
  if (!zerocount){
    return snum
  }
  if (!snum){
    return null
  }

  snum = snum.toString()

  let len = snum.length
  let a = null

  if (zerocount == len){
    a = `0.${snum}`
  }else if (zerocount < len){
    a = `${snum.substr(0, len - zerocount)}.${snum.substr(len - zerocount)}`
  }else if (zerocount > len){
    let p = ""
    for (let j = 0; j < (zerocount - len); j++){
      p = `${p}0`
    }
    a = `0.${p}${snum}`
  }

  let r = ""
  for (let i = a.length - 1; i > 0; i--){
    let c = a.substr(i,1)
    if ("0" != c){
      let r = a.substr(0, i+1)
      if ('.' == r.substr(r.length-1, 1)){
        r = r.substr(0, r.length-1)
      }
      return r
    }
  }
  return snum
}
export { strdiv };

export const strPrice = (v) => {
  if (!v){
    return '0'
  }
  let vv = v.toString();
  return strdiv(vv, 18);
}


function _remove_el_in_array_by_id({arr, id}){
  if (!arr || !arr.length || !id){
    return false;
  }
  let m = arr.filter(v => v.id == id);
  if (m && m[0]){
    arr.splice(arr.indexOf(m[0]) ,1)
  }
}


export const mesg = () => {
  return {
    msg: null,
    loading: false,
    err: null,
    success: null,
    info: null
  }
};

/*
  一个很有趣的简洁 win dom 的封装
*/
export const o0win = {
  pageX: 0,
  pageY: 0,
  scrollTop: 0,
  scrollLeft: 0,
  width: $(window).width(),
  height: $(window).height(),
  docHeight: $(document).height(),
  //判断一个元素是否出现在窗口视野中
  _see_list: [],
  see: function ({dom, id, callback}) {

    // console.log('see this', this);

    if (!dom){
      callback && callback(false)
      return false
    }


    let top = $(dom).offset().top
    if (top <= this.height){
      //第一屏直接显示
      callback && callback(true)
    }

    this._see_list.push({
      id: id,
      dom: dom,
      callback: callback
    })

  },
  seeRefresh: function(){
    this._do_see();
  },
  seeRemove: function(id){
    return _remove_el_in_array_by_id({
      arr: this._see_list,
      id: id
    })
  },
  _do_see_int: null,
  _do_see_lock: false,
  _do_see: function(){

    //限制调用次数
    // clearTimeout(this._do_see_int)
    if (this._do_see_lock){
      return
    }
    this._do_see_lock = true
    this.__do_see()

    // this._do_see_int = setTimeout(()=>{
    //   this.__do_see()
    //   this._do_see_lock = false
    // }, 200)
    
    this._do_see_int = setTimeout(()=>{
      this.__do_see()
      this._do_see_lock = false
    }, 200)


  },
  __do_see: function(){

    if (this._see_list && this._see_list.length){
      this._see_list.map(st => {

        let dom = st.dom
        let callback = st.callback

        let top = $(dom).offset().top
        let height = 2000;//让缓存宽一点  // 400;//$(dom).height()

        // console.log('see =======================');
        // console.log('see', top, this.scrollTop, this.height);
        // console.log('see', (this.scrollTop + this.height), (top + height));

        //元素超出视野
        // if (top > (this.scrollTop + this.height)){
        if (top > (this.scrollTop + this.height + 2000)){ //让缓存宽一点
          callback && callback(false)
          return;
        }

        //元素在上面
        if ((top + height) < (this.scrollTop)){
          callback && callback(false)
          return;
        }

        callback && callback(true)
        return

      })
    }
  },
  _catchBottom_callback_list: [],
  catchBottom: function({id, callback}){
    this._catchBottom_callback_list.push({
      id: id,
      callback: callback
    })
  },
  _do_catchBottom: function(){
    if (this._catchBottom_callback_list && this._catchBottom_callback_list.length){
      if ((o0win.height + o0win.scrollTop) >= o0win.docHeight){
        this._catchBottom_callback_list.map(st => {
          st.callback && st.callback(true)
        })
      }
    }
  },
  catchBottomRemove: function(id){
    return _remove_el_in_array_by_id({
      arr: this._catchBottom_callback_list,
      id: id
    })
  }

}


$(window).on('mousemove', function(e){
    o0win.pageX = e.pageX;
    o0win.pageY = e.pageY;
})

$(window).on('scroll', function(event){
    o0win.scrollTop = $(window).scrollTop()
    o0win.scrollLeft = $(window).scrollLeft()
    o0win.docHeight = $('body').height()

    o0win._do_see();
    // console.log('catchBottom', o0win.height, o0win.scrollTop, (o0win.height + o0win.scrollTop), o0win.docHeight, (o0win.height + o0win.scrollTop) >= o0win.docHeight);
    
    o0win._do_catchBottom();
    
});

$(window).on('resize', function(event){
    o0win.width = $(window).width()
    o0win.height = $(window).height()
    o0win.docHeight = $('body').height()

    o0win._do_see();
    o0win._do_catchBottom();
});



// import md5 from 'md5'

//commonPromise 多处查询接口同时调用，只返回一个 promise
/*
  fn            原始函数
  fnParams      原始函数变量
*/

// let cachedVar = {};


// export const commonPromise = (that, fn, fnParams) => {


//   if (!fnParams){
//     fnParams = {};
//   }

//   let cachedKey;

//   try {
//     cachedKey = md5(JSON.stringify(fnParams));
//   }catch(e){
//     cachedKey = md5(fnParams.toString());
//   }

//   fnParams.cpCallback = {
//     callback: ()=>{
//       cachedVar[cachedKey] = null;
//     }
//   }

//   if (cachedVar[cachedKey]){
//     return cachedVar[cachedKey];
//   }

//   cachedVar[cachedKey] = fn.bind(that, fnParams)();
//   return cachedVar[cachedKey]
// }


export const isArraySet = (a) => {
  if (isArray(a)){
    if (a.length){
      return true;
    }
  }
  return false;
}


export const aPush = (a, v) => {
  if (isArray(a)){
    a.push(v)
  }else{
    a = [v]
  }
}


/*
  @function attrTest
  判断一个对象的属性是否存在

  @param {object}         object      要测试的对象
  @param {string}         attrString  属性名字符串
  @param {string}         matchType   要匹配的类型 [可选] ['array']

  @example
  attrTest(project, 'projectdetailprices.items')

*/

export const getAttr = (object, attrString, matchType) => {

  try {
      attrString = 'object.' + attrString;

      let r = eval(attrString);

      if (r){
        return r;
      }

  }catch(e){
    // console.log('attr catch', e);
    //nothing
  }

  return null;

}


export const attrTest = (object, attrString, matchType) => {

  try {
      attrString = 'object.' + attrString;

      let r = eval(attrString);

      switch(matchType){
        case 'array':
          if (isArray(r)){
            return true;
          }
          break;
        default:
          return true;
          break;
      }
  }catch(e){
    // console.log('attrTest err', e);
    //nothing
  }

  return false;

}


export const arrayExist = (object, attrString) => {
  return attrTest(object, attrString, 'array')
}


/*
  @function inputFilter
  取一个对象的某些字段

  @param {object} params 将要取值的对象

  {
    id: 'id1',
    name: 'My Name',
    other: 'abc'
    ...
  }

  @param {array} fields 字段名称数组

  ['id', 'name', ...]

  @example
  inputFilter({id: 'id1', name: 'My Name', other: 'abc'}, ['id', 'name']);

  @return 
  {
    id: 'id1',
    name: 'My Name'
  }

*/
export const inputFilter = (params, fields) => {

  let input = {};
  for (let k in params){
    if (fields.includes(k)){
      input[k] = params[k];
    }
  }
  return input;
}



export const parameterdata = {

  set:(object, field, value) => {
    let o = object;
    if (!o){
      o = {}
    }
    if (isString(o)){
      o = JSON.parse(o);
    }
    o[field] = value;
    return o;
  },

  get:(object, field) => {
    try {
      return object[field]
    }catch(e){
      return null;
    }
  }

}


/* dofoo 把组件内常用的代码结构封装，如
  async getList(){
    this.mesg.loading = true;
    this.mesg.err = null;
    try {
      this.desserts = await ProjectService.listQuotes();
    }catch(e){
      this.mesg.err = e;
    }
    this.mesg.loading = false;
  }
  封装成：
  dofoo(()=>{
    this.desserts = await ProjectService.listQuotes();
  },this.mesg)
*/
export const dofoo = async (fun, mesg, mesg2) => {

  // console.log('dofoo', mesg, mesg2);

  if (mesg) {
    mesg.loading = true;
    mesg.err = null;
    mesg.msg = [];
  }

  if (mesg2) {
    mesg2.loading = true;
    mesg2.err = null;
    mesg2.msg = [];
  }

  try {
    await fun({msg: mesg.msg});
  }catch(e){
    if (mesg) mesg.err = e;
    if (mesg2) mesg2.err = e;
  }
  if (mesg) {
    mesg.loading = false;
    mesg.msg = [];
  }

  if (mesg2) {
    mesg2.loading = false;
    mesg2.msg = [];
  }
}

export const svg2base64 = (dom) => {
  const svg = dom //document.getElementById('mySvg');
  const s = new XMLSerializer().serializeToString(svg);
  const ImgBase64 = `data:image/svg+xml;base64,${window.btoa(s)}`;
  return ImgBase64
}



export const getBase64Image = (img) => {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  var dataURL = canvas.toDataURL("image/png");  // 可选其他值 image/jpeg
  return dataURL;
}


export const canvasToBlob = (canvas) => {
  return new Promise(async (resolve, reject) => {
    try {
      canvas.toBlob((blobObj) => {
        resolve(blobObj);
      })      
    }catch(e){
      reject(e)
    }
  });  
}

export const isLangCn = () => {
  try {
    var lang = window.navigator.language || window.navigator.userLanguage;
    lang = lang.substr(0, 2);
    if(lang == 'zh'){
        return true;
    }else{
        return false;
    }
  }catch(e){
    return false
  }
}

export const blobToFile = (blob, filename, filetype) => {
  return new File([blob], filename, {type: filetype})
}

export const canvasToFile = (canvas, filename, filetype) => {
  return new Promise(async (resolve, reject) => {
    try {
      canvas.toBlob((blobObj) => {
        resolve(blobToFile(blobObj, filename, filetype));
      })      
    }catch(e){
      reject(e)
    }
  });
}

export const getFileType = (filepath) =>{
    try {
      let ext = filepath.split('.').pop();
      let result = mime.find(ext);
      if (false === result){
        return 'binary/octet-stream';
      }
      return result;
    }catch(e){
        return 'binary/octet-stream';
    }
  }

export const $currency = (src) => {
  // 'USD'

    // console.log('in fun.const.js', src);
  return CurrencyService.$currency();

}

export const $num = (src) => {

  return (parseFloat(src)).toLocaleString('en-US')

}


export const signFixed = (value, num) => {

  try {

    if (!num){
      num = 5;
    }

    let a = (parseFloat(value)).toLocaleString('en-US');
    let ext = a.split('.')[0];

    let c = (parseFloat(value)).toFixed(num);
    let ext2 = c.split('.')[1]

    return `${ext}.${ext2}`

  }catch(e){

    console.log('signFixed err', e);

  }

  return value;

}


export const $tcy = (value, num, notFormat) => {
  // 100, 'USD', 'CNY'


  if (true === num){
    notFormat = true;
    num = null;
  }

  if (isNaN(value)){
    return '-'
  }

  let r = 0;


  if (isArray(value)){
    let c = 0;
    value.map(st => {
      c += Number(CurrencyService.$t(st, num));
    })
    r = c.toFixed(2);


  }else{
    r = CurrencyService.$t(value, num);
  }

  if (!notFormat){
    // if (r >= 1000){
      return signFixed(r, num);
      // return (parseFloat(r)).toLocaleString('en-US')
    // }
  }
  return r


}





export const $tcyf = (value, num, notFormat) => {

  if (true === num){
    notFormat = true;
    num = null;
  }

  // 100, 'CNY', 'USD'
  if (isNaN(value)){
    return '-'
  }

  let r = CurrencyService.$tf(value, num);

  if (!notFormat){
    return (parseFloat(r)).toLocaleString('en-US')
  }
  return r

}

export const sleep = (time) => {

    // //console.log('in sleep', time);
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        // //console.log('out sleep', time);
        resolve();
      }, time)
    });

}

export const tryFun = (fn) => {

    return new Promise(async (resolve, reject) => {

      const TRY_MAX_COUNT = 3;
      let c = 0;
      while(true){
        try{
          let result = await fn();
          resolve(result);
          return 
        }catch(e){

          let eStr = e.toString();
          if ((-1 != eStr.indexOf('403')) || (-1 != eStr.indexOf('404'))){
            //报错是没有这个文件，而非网络出错
            reject(e);
            return;
          }

          if (c >= TRY_MAX_COUNT){
            reject(e);
            return;
          }
          c++;
          await sleep(3000);
        }
      }

    });

}

export const ellipsis = (str, len) => {
  // console.log('ellipsis', str, len);
  if (str.length <= len){
    return str;
  }
  try {
    let ext = str.split('.').pop();
    if (ext == str){
      return str.substr(0,len) + '..'
    }
    let pre = str.substr(0, len-ext.length-1);
    return `${pre}...${ext}`;
  }catch(e){}
  return str.substr(0,len) + '..'
}

// 过滤文件名显示，只显示最末一层，不显示前面的路径
export const nameFilter = (name, len) => {

  if (!name){
    return '';
  }
  if (!len){
    len = 16
  }
  try {
    let s = name.toString().split('/').pop();

    return ellipsis(s, len);
  }catch(e){
    return ellipsis(name, len);
  }
}


let tlog_d;
export const tlog = (msg) => {
  if (process.env.NODE_ENV === 'development'){
    let d = new Date().getTime();
    let dd;
    if (tlog_d){
      dd = (d - tlog_d)/1000;
      // console.log(d, dd, msg);
    }else{
      // console.log(d, msg);
    }
    tlog_d = d;

  }
}

export const slog = (msg) => {
  if (process.env.NODE_ENV === 'development'){
    tlog_d = new Date().getTime();
  }
}
export const elog = (msg) => {
  if (process.env.NODE_ENV === 'development'){
    let d = new Date().getTime();
    let dd = (d - tlog_d) / 1000;
    // console.log(dd, msg);
  }
}

/*
3秒防抖动函数
let foo = debounce(this._foo, 3000);
foo.run(); //开始,触发计时器
foo.clear(); //请除计时器（这样下一次不会自动执行）
*/

let _funDebounce = (func, delay = 3000) => {
  let timeout = null
  return {
    run: () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        func.apply(this, arguments)
      }, delay)
    },
    clear: () => {
      if (timeout){
        clearTimeout(timeout)
      }
    }
  }
}

export const debounce = _funDebounce;
export const funDebounce = _funDebounce;

export const formatDate = (value, hide_second, utc) => {// 时间戳转换日期格式方法

    if (!value){
      return null
    }

    if ('object' == typeof value){
      try {
        return getDateTimeString(value, hide_second)
      }catch(e){
        //nothing
      }
    }

    let n = parseInt(value)
    if (n < 9999999999){
      n *= 1000;
    }

    if (global.UTC_FORCE || utc){
      let ot = new Date().getTimezoneOffset();
      n += ot * 60 * 1000;
    }

    let date = new Date(n);
    return getDateTimeString(date, hide_second)
}

function getDateTimeString(date, hide_second){
  let y = date.getFullYear();// 年
  let MM = date.getMonth() + 1;// 月
  MM = MM < 10 ? ('0' + MM) : MM;
  let d = date.getDate();// 日
  d = d < 10 ? ('0' + d) : d;
  let h = date.getHours();// 时
  h = h < 10 ? ('0' + h) : h;
  let m = date.getMinutes();// 分
  m = m < 10 ? ('0' + m) : m;
  let s = date.getSeconds();// 秒
  s = s < 10 ? ('0' + s) : s;
  if (hide_second){
    return y + '-' + MM + '-' + d;
  }else{
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
  }
}

export const formatSize = (value) => {// 时间戳转换日期格式方法
    if (value == null) {
        return 0;
    } else {

        try {

          value = parseInt(value);
          if (value > 1024 * 1024 * 1024){
            value = value / (1024 * 1024 * 1024);
            value = value.toFixed(2)
            return `${value}GB`
          }
          if (value > 1024 * 1024){
            value = value / (1024 * 1024);
            value = value.toFixed(2)
            return `${value}MB`
          }
          if (value > 1024){
            value = value / (1024);
            value = value.toFixed(2)
            return `${value}KB`
          }

        }catch(e){

          console.log('formatSize err', e);

        }

        return `${value}B`
    }
}




export const getQueryVariable = (variable) => {
   try {

     var query = window.location.search.substring(1);
     var vars = query.split("&");
     for (var i=0;i<vars.length;i++) {
             var pair = vars[i].split("=");
             if(pair[0] == variable){return pair[1];}
     }

   }catch(e){

   }

   return(false);
}
