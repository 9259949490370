export default {
  currentLang: 'English',
  locale:'en',
  licp:'LICP',
  logout: 'Logout',
  menu: {
    my_tokens: 'Wallet',
    stake: 'Stake',
    swap: 'Swap',
    rewards: 'Rewards',
    pools: 'Pools'
  },
  page_login:{
    welcome: 'Welcome to LICP',
    please_login: 'Please choose one of the following methods to log in.',
  },
  symbol: {
    XTC_CYCLES: 'XTC (cycles)',
    XTC: 'XTC',
    ICP: 'ICP',
    LICP: 'LICP',
    internet_computer: 'Internet Computer',
  },
  page_my_tokens:{
    my_tokens: 'My Wallet',
    title_desc: 'Manage tokens in my wallet',
    tokens: 'Tokens',
    receive: 'Receive',
    token: 'Token',
    balance: 'Balance',
    send: 'Send',
    price: 'Est. Value',
    receive_icp: 'Receive Tokens',
    send_icp: 'Send Tokens',
    available: 'Available',
    total: 'Total',
    swap: 'Swap',
    setting: 'Setting',
    select_token: 'Select Token',
    cancel: 'Cancel',
    continue: 'Continue',
    principal_id: 'ICRC-1 Account (for all tokens including ICP)',
    account_id: 'ICP AccountID (legacy, ICP only)',
    address_reciplent: 'Address of the Reciplent',
    amount: 'Amount',
    transaction_fee: 'Transaction Fee',
    slippage_tolerance: 'Slippage tolerance',
    destination_address: 'Destination Address',
  },
  page_stake:{
    stake_icp: 'Stake ICP',
    title_desc: 'Stake ICP and receive LICP',
    step1: 'Send ICP',
    step2: 'Mint LICP',
    balance: 'Balance',
    withdraw: 'Withdraw',
    send: 'Send',
    wallet_balance: 'Wallet Balance',
    stake: 'Stake',
    you_will_receive: 'You will receive',
    exchange_rete: 'Exchange rate',
    max_transaction_cost: 'Max transaction cost',
    reward_fee: 'Reward fee',
    licp_statistics: 'LICP statistics',
    annual_percentage_rate: 'Current APR',
    total_staked_with_licp: 'Total ICP staked',
    licp_market_cap: 'LICP market cap',
    stakers: 'Stakers',
  },
  page_swap:{
    swap: 'Swap',
    swap_your_tokens: 'Swap your tokens',
    balance: 'Balance',
  },
  page_rewards:{
    reward_history: 'Reward History',
    title_desc: 'Track your ICP staking rewards',
    receive_icp: 'Receive ICP',
    no_staking: 'You don\'t have staked tokens. Stake now and receive daily rewards.',
  },
  page_pools:{
    pools: 'Pools',
    title_desc: '',
  },
  user_header:{
    apr: 'APR',
    balance: 'Balance',
    staked_amount: 'Staked amount',
    available_to_stake: 'Available to stake',
  }
}