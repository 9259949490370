<template>

  <div>
    <v-btn color="primary" @click="btnUpload_OnClick()" 
        :loading="loading" 
        :disabled="disabled"
         small large >
      <v-icon left dark>mdi-cloud-upload</v-icon>{{ btnTitle }}
    </v-btn>
    <input style="display:none" type="file" @change="file_OnChange($event)" ref="inputFile" />
  </div>
      
</template>


<script>


export default {
  props: {
    loading: {
      default: false
    },
    disabled: {
      default: false
    },
    btnTitle: {
      default: 'Upload'
    }
  },
  computed:{
  },
  methods: {
    file_OnChange($event){
      if (this.$refs.inputFile.files && this.$refs.inputFile.files[0]){
        this.$emit('change', this.$refs.inputFile.files[0]);
      }
    },
    btnUpload_OnClick(){
      this.$refs.inputFile.value = '';
      this.$refs.inputFile.click();
    }
  }
}
</script>