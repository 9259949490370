
export const currentUnixTimestamp = (o) => {
  let d = new Date().getTime();
  d = d / 1000;
  d = Math.round(d);
  return d;
}

export const isStrTestAa1 = (o) => {

  if (o){
    if (/^[0-9A-Za-z]+$/.test(o)){
      return true;
    }
  }

  return false;

}

export const isStrongPassword = (o) => {

  if (o){
    if (/[a-z]/.test(o)){
      if (/[A-Z]/.test(o)){
        if (/[0-9]/.test(o)){
          if (/^[0-9A-Za-z]+$/.test(o)){
            return true;
          }
        }
      }
    }
  }

  return false;

}

export const isEmail = (o) => {

  if(o != "") {
    var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    let isok= reg.test(o);
    if(!isok) {
      return false;
    }
    return true;
  };
  return false;

}


export const isObject = (o) => {
  return Object.prototype.toString.call(o)=='[object Object]';
}


export const isArray = (o) => {
  return Object.prototype.toString.call(o)=='[object Array]';
}

export const testArray = (o) => {
  if (isArray(o)){
    if (o.length){
      return true;
    }
  }
  return false;
}

export const isString = (str) => {
  if(Object.prototype.toString.call(str) === "[object String]"){
    return true;
  }else{
    return false;
  }
}

export const isNumber = (str) => {
  if (0 === str){
    return true;
  }
  if ((null === str) || ('' === str) || (undefined === str)){
    return false;
  }
  if ((Infinity === str) || (-Infinity === str)){
    return true;
  }
  if (!isNaN(str)){
    return true;
  }
  return false;
}

export const isNotEmpty = (v) => {
  if (null == v){
    return false
  }
  if ('' == v){
    return false
  }
  if (0 === v){
    return true;
  }
  if (isArray(v)){
    return v && v.length;
  }
  try {
    if (isObject(v)){
      for (let k in v){
        if (k){
          return true;
        }
      }
    }
  }catch(e){
  }
  if (v){
    return true;
  }
  return false;
}



export const toFixed = (v, num) => {

  try {
    let t = parseFloat(v);
    return t.toFixed(num);
  }catch(e){
    return v
  }

}
