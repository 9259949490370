// connect2ic vuex store

import { createClient } from "@connect2ic/core"
import { Principal } from "@dfinity/principal"
 
import {
  AstroX,
  defaultProviders,
  InternetIdentity,
  StoicWallet,
  InfinityWallet,
  ICX,
  PlugWallet,
  NFID,
} from "@connect2ic/core/providers"

import {
    principalToAccountIdentifier,
    generateMnemonic,
    mnemonicToSeed,
    createIdentity,
    exportPem,
    importPem,
    identityToPrincipal,
    generateIdentity,
    getBalance,
    getXtcBalance,
    transferIcp,
    getSubAccountArray,
    getAccountBalance,
    getIcpRate
  } from "./myWallet";
import ledger_idl from '@/candid/ledger.did.js';
import csdemo_idl from '@/candid/csdemo_backend.did.js';
import { idlFactory as cmc_idl } from '@/candid/cmc.did.js';
import { LEDGER_CANISTER_ID, CSDEMO_CANSITER_ID } from '@/candid/canister_ids.js';
import Identicon from 'identicon.js';
// import md5 from 'md5'
var md5 = require('md5');

const client:any = createClient({
    // providers: defaultProviders,
    providers: [
      (window as any).icx
        ? new ICX({
            // providerUrl: "https://ccmhe-vqaaa-aaaai-acmoq-cai.raw.ic0.app/",
            // providerUrl: "http://localhost:8080/",
            // customDomain: "http://localhost:8080",
            customDomain: "https://icfoo.turbolinking.com",
          })
        : new AstroX({
            providerUrl: "https://63k2f-nyaaa-aaaah-aakla-cai.raw.ic0.app",
            // ledgerCanisterId: "ryjl3-tyaaa-aaaaa-aaaba-cai",
            // ledgerHost: "https://boundary.ic0.app/",
            // host: window.location.origin,
            // dev: true,
            // noUnify: false,
            delegationModes: ["global"],
            // providerUrl: "https://ccmhe-vqaaa-aaaai-acmoq-cai.raw.ic0.app",
            // providerUrl: "http://localhost:3000",
            // customDomain: "http://localhost:8080",
            customDomain: "https://icfoo.turbolinking.com",
          }),
      new NFID(),
      // new InfinityWallet(),
      // new PlugWallet(),
      new InternetIdentity(),
    ],
    globalProviderConfig: {
    //   host: 'https://icp-api.io',
      host: 'https://ic0.app',
      whitelist: ['ryjl3-tyaaa-aaaaa-aaaba-cai', 'aanaa-xaaaa-aaaah-aaeiq-cai'],
      appName: 'LICP',
      dev: true,
    },
})

const state = {
    c2ic_rnd: null,
    c2ic_balance: 0,
    c2ic_xtc_balance: 0,
    c2ic_skipConnect: false,
    c2ic_icp_rate: 0
}

async function timeloop(){

    state.c2ic_icp_rate = await getIcpRate()

    if (client.principal){
        // const account:any = principalToAccountIdentifier(client.principal) 
        // console.log('timeloop account', account)
        state.c2ic_balance = await getAccountBalance({principal: client.principal} /*'fcb91dddf38ca7dbad713222f98a368aac79ee27e4472ebd10b1eca4932fa39f'*/)
        console.log('timeloop c2ic_balance', state.c2ic_balance)
        
        state.c2ic_xtc_balance = await getAccountBalance({principal: client.principal, type:'xtc'} /*'fcb91dddf38ca7dbad713222f98a368aac79ee27e4472ebd10b1eca4932fa39f'*/)
        console.log('timeloop xtc', state.c2ic_balance)

        //1dc767c5269a740aa4d18e22eecce26dde1333672b60f61167e9a86a0c020000
        //87eaf211658e2e8a8ed702811dd9b02c24b2e6fef37928932d28f794380d04b1
        // let test_balance = await getAccountBalance({account: '87eaf211658e2e8a8ed702811dd9b02c24b2e6fef37928932d28f794380d04b1'} /*'fcb91dddf38ca7dbad713222f98a368aac79ee27e4472ebd10b1eca4932fa39f'*/)
        // console.log('test_balance', test_balance)
        
    }
    
    state.c2ic_rnd = Math.random();
    
    setTimeout(timeloop, 20000);
}

 setTimeout(timeloop, 10000);

client.on("connect", async function(e){
    console.log('c2ic connect', e, client)
    await timeloop()
    state.c2ic_rnd = Math.random();


    // let queryBalance = await e.activeProvider.queryBalance();
    // console.log('queryBalance', queryBalance)
    // console.log('queryBalance amount', queryBalance.value[0].amount)
})
client.on("disconnect", function(e){
    state.c2ic_rnd = Math.random();
    console.log('c2ic disconnect', e)
})


const getters = {
    C2IC_Avatar: (state, getters) => {
        let hash = getters.C2IC_PrincipalText
        if (!hash){
            hash = 'hash'
        }
        hash = md5(hash)
        var data = new Identicon(hash).toString();
        return `data:image/png;base64,${data}`
    },
    C2IC_Account: (state, getters) => {
        state.c2ic_rnd
        if (!getters.C2IC_PrincipalText){
            return ''
        }
        return  principalToAccountIdentifier(getters.C2IC_PrincipalText) 
    },
    C2IC_BalanceUsd: (state, getters) => {
        state.c2ic_rnd
        if (state.c2ic_skipConnect){
            return 0
        }
        // return ((1000000 / 100000000) * state.c2ic_icp_rate).toFixed(2)
        try {
            if (state.c2ic_balance){
                return ((state.c2ic_balance / 100000000) * state.c2ic_icp_rate).toFixed(2)
            }
        }catch(e){

        }
        return 0;
        // return state.c2ic_balance
    },
    C2IC_Balance: (state, getters) => {
        state.c2ic_rnd
        if (state.c2ic_skipConnect){
            return 0
        }
        // return (1000000 / 100000000).toFixed(5)
        try {
            if (state.c2ic_balance){
                return (state.c2ic_balance / 100000000).toFixed(5)
            }
        }catch(e){

        }
        return 0;
        // return state.c2ic_balance
    },
    C2IC_XtcBalance: (state, getters) => {
        state.c2ic_rnd
        if (state.c2ic_skipConnect){
            return 0
        }
        try {
            if (state.c2ic_xtc_balance){
                return (state.c2ic_xtc_balance / 100000000).toFixed(5)
            }
        }catch(e){

        }
        return 0;
        // return state.c2ic_balance
    },
    C2IC_ClientProviders: (state, getters) => {
        state.c2ic_rnd
        return client.providers
    },
    C2IC_IsConnected: (state, getters) => {
        state.c2ic_rnd
        if (state.c2ic_skipConnect){
            return true
        }
        if ('connected' == client._service._state.value?.idle){
            return true;
        }
        return false;
    },
    C2IC_Principal: (state, getters) => {
        state.c2ic_rnd
        if (state.c2ic_skipConnect){
            return Principal.fromText('2vxsx-fae');
        }
        return Principal.fromText(client.principal);
    },
    C2IC_PrincipalText: (state, getters) => {
        state.c2ic_rnd
        if (state.c2ic_skipConnect){
            return '2vxsx-fae';
        }
        return client.principal
    },
}

const actions = {
    async C2IC_IcpRate({ commit, state, getters, dispatch }) {

        state.c2ic_icp_rate = await getIcpRate()
        console.log('C2IC_IcpRate', state.c2ic_icp_rate)
        return state.c2ic_icp_rate;
    },
    async C2IC_XtcDemo({ commit, state, getters, dispatch }) {

        const ledger = await dispatch('C2IC_CreateActor', { canid: 'rkp4c-7iaaa-aaaaa-aaaca-cai', idl: cmc_idl })
        const result = await ledger.get_icp_xdr_conversion_rate();
        const xdr_permyriad_per_icp = result.data.xdr_permyriad_per_icp;
        
        const CYCLES_PER_XDR = BigInt(1_000_000_000_000);
        // trillionRatio
        let icpXdrConversionRate = (xdr_permyriad_per_icp * CYCLES_PER_XDR) / BigInt(10_000);

        console.log('C2IC_XtcDemo', ledger, icpXdrConversionRate)

    },
    async C2IC_CSDEMO({ commit, state, getters, dispatch }) {

        const ledger = await dispatch('C2IC_CreateActor', { canid: CSDEMO_CANSITER_ID, idl: csdemo_idl })
        console.log('C2IC_CSDEMO ledger', ledger)

        const get_subaccount_string = await ledger.get_subaccount_string();
        console.log("get_subaccount_string:", get_subaccount_string);
        
        const get_subaccount_hex = await ledger.get_subaccount_hex();
        console.log("get_subaccount_hex:", get_subaccount_hex);


        const get_subaccount = await ledger.get_subaccount();
        console.log("get_subaccount:", get_subaccount);
        
        // let to_addr = '';
        // get_subaccount.map(st => {
        //   let s = st.toString(16);
        //   if (s.length < 2){
        //     s = '0' + s;
        //   }
        //   let u = s.toString()
        //   // console.log('s', st, st.toString(16), u);
        //   to_addr += u;
        //   // return u;
        // })
        
        
        // console.log("to_addr:", to_addr);


        const select_canister_account_id = await ledger.select_canister_account_id();
        console.log("select_canister_account_id:", select_canister_account_id);
        

        return get_subaccount_string;

    },
    async C2IC_Transfer({ commit, state, getters, dispatch }, { to, amount, amount_string, fromSubAccount = 0, memo = 0 }) {

        const ledger = await dispatch('C2IC_CreateActor', { canid: LEDGER_CANISTER_ID, idl: ledger_idl })

        const fee: bigint = BigInt(10000); // Set the transaction fee, adjust as needed

        if (!amount){
            if (amount_string){
                amount = Number(amount_string) * 100000000
            }else{
                throw new Error('amount must be provided')
            }
        }
        
        const sendArgs = {
            to: to,
            fee: { e8s: fee },
            memo: memo ? Number(BigInt(memo)) : 0,
            from_subaccount: [getSubAccountArray(fromSubAccount ?? 0)], // Use the provided subaccount or default to 0
            created_at_time: [], // Use the default timestamp
            amount: { e8s: amount },
        };
        
        console.log('sendArgs', sendArgs);
        const result = await ledger.send_dfx(sendArgs);
        console.log("Transfer result:", result);
        
        await dispatch('C2IC_QueryBalance')
        await dispatch('C2IC_Rnd')

        return result;

    },
    async C2IC_SkipConnect({ commit, state, getters, dispatch }) {
        state.c2ic_skipConnect = true;
    },
    async C2IC_QueryBalance0({ commit, state, getters, dispatch }) {
        if (state.c2ic_skipConnect){
            return;
        }
        if (!getters.C2IC_IsConnected){
            return;
        }

        let queryBalance = await client.activeProvider.queryBalance();
        state.c2ic_balance = queryBalance.value[0].amount
        await dispatch('C2IC_Rnd')
        console.log('state.c2ic_balance', state.c2ic_balance)
    },
    async C2IC_CreateActor({ commit, state, getters, dispatch }, { canid = LEDGER_CANISTER_ID, idl = ledger_idl}) {
        console.log('in C2IC_CreateActor')
        if (state.c2ic_skipConnect){
            return;
        }
        if (!getters.C2IC_IsConnected){
            return;
        }
        console.log('client', client)
        const createdActor = await client.activeProvider.createActor(canid, idl);
        console.log('createdActor', createdActor)
        const ledger = createdActor.value;
        console.log('ledger', ledger)
        return ledger;
    },
    async C2IC_QueryBalance({ commit, state, getters, dispatch }) {
        console.log('in C2IC_QueryBalance')
        if (state.c2ic_skipConnect){
            return;
        }
        if (!getters.C2IC_IsConnected){
            return;
        }
        // console.log('A')
        const createdActor = await client.activeProvider.createActor('ryjl3-tyaaa-aaaaa-aaaba-cai', ledger_idl);
        console.log('createdActor', createdActor)
        const ledger = createdActor.value;
        console.log('ledger', ledger)
        const arg = { account: getters.C2IC_Account };
        // console.log('arg', arg)
        const result:any = await ledger.account_balance_dfx(arg);
        console.log('ICP Balance:', result.e8s, Number(result.e8s) / 100000000);
        state.c2ic_balance = Number(result.e8s) 
        await dispatch('C2IC_Rnd')
        // console.log('state.c2ic_balance', state.c2ic_balance)
      

        // let queryBalance = await client.activeProvider.queryBalance();
        // state.c2ic_balance = queryBalance.value[0].amount
        // await dispatch('C2IC_Rnd')
    },
    async C2IC_CopyAccount({ commit, state, getters, dispatch }) {
        let account = getters.C2IC_Account
        if (account){
            await navigator.clipboard.writeText(account)
        }
    },
    async C2IC_CopyPrincipal({ commit, state, getters, dispatch }) {
        let principalText = getters.C2IC_PrincipalText
        if (principalText){
            await navigator.clipboard.writeText(principalText)
        }
    },    
    async C2IC_Rnd({ commit, state, getters, dispatch }) {
        state.c2ic_rnd = Math.random();
    },
    async C2IC_Connect({ commit, state, getters, dispatch }, { id }) {
        console.log('in C2IC_Connect', id)
        await client.connect(id)
        await dispatch('C2IC_QueryBalance')
        await dispatch('C2IC_Rnd')
        console.log('in C2IC_Connect end', id)
    },
    async C2IC_Disconnect({ commit, state, getters, dispatch }) {
        console.log('in C2IC_Disconnect')
        state.c2ic_skipConnect = false;
        await client.disconnect()
        await dispatch('C2IC_Rnd')
    }
}

const mutations = {
    IISetIdentity(state, { identity }) {
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
