import Vue from 'vue'
import Vuex from 'vuex'

import mdlConnect2Ic from './mdlConnect2Ic'

Vue.use(Vuex)

const isDev = false;

export default new Vuex.Store({
  modules: {
    mdlConnect2Ic
  },
  state: {
  },
  getters: {   
  },
  mutations: {
  },
  actions: {    
  }
})
