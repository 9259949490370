
import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    btnLogout_OnClick(){
      this.$store.dispatch('logout')
      location.reload();
    }
  }
});
