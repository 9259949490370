import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import { vuetify, i18n } from './plugins/vuetify'
import '@/assets/main.less'
import VConsole from "vconsole"
const vConsole = new VConsole()
import "@connect2ic/core/style.css"

const { Buffer } = require("buffer");

// 确保 Buffer 可用于全局上下文
if (typeof window !== "undefined") {
  window.Buffer = Buffer;
}

//fix process is not defined
if (typeof window !== "undefined") {
  (window as any).process = {
    // env: { NODE_ENV: "production" }
    env: { NODE_ENV: "development" }
  };
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
