import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import VueI18n from 'vue-i18n'
import en from '@/plugins/i18n/en'

const messages = {
  en: en
}

Vue.use(VueI18n);
Vue.use(Vuetify);

export const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
})

export const vuetify = new Vuetify({
  lang: {
    t: (key, ...params) => {
    	let r:any = i18n.t(key, params);
    	return r;
    },
  },
});
