<template>

    <v-btn color="green" @click="btn_OnClick()" 
        :loading="loading" 
        :disabled="disabled"
        style="min-width:0;padding:0"
        text small  >
      <v-icon 
        dark

      >mdi-check</v-icon>
    </v-btn>
      
</template>


<script>


export default {
  props: {
    loading: {
      default: false
    },
    disabled: {
      default: false
    }
  },
  computed:{
  },
  methods: {
    btn_OnClick(){
      this.$emit('click');
    }
  }
}
</script>