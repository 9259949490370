
class commonSubject {
	// subject = new Rx.Subject();
	subject = new Rx.BehaviorSubject();
	subscribe(fn){

	// console.log('subject', this.subject);
		let sub = this.subject.asObservable();
		let subscription = sub.subscribe({
			next: (res) => {
				if (!res){
					return;
				}
				if (fn){
					fn(res);
					// console.log('in sub commonSubject');
				}
			}
		});
		return subscription;
	}
	next(value){
		// console.log('commonSubject next', value);
		this.subject.next(value);
	}
}


export const SubjectCommonMessage = new commonSubject();  // common message





/* 

import { SubjectCommonMessage } from '@/libs/subject';

Subscription:
let subscription = SubjectCommonMessage.subscribe((v) => {
	console.log('observerA: ' + v)
});

Cancel:
subscription.unsubscribe()

Post value:
SubjectCommonMessage.next('abcde');

*/



