<template>
  <div style="vertical-align: 3px;display: inline-block;">
  	<v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
		<v-icon  
			color="blue" 
			v-bind="attrs"
	        v-on="on" 
	        style="font-size:16px" >
			mdi-help-circle
		</v-icon>
      </template>
      <v-card style="width:300px" class="text-high"><v-card-text>{{ text }}</v-card-text></v-card>
    </v-tooltip>
  </div>
</template>


<script>
const HelpTips = {
  components: {
  },
  props: {
    text: null
  },
}

export { HelpTips } ;
export default HelpTips ;

</script>